<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p> -->
    <div class="Call" v-if="!form">
    <div class="remote-container" id="local">
      <div id="localStream" class="remote-stream-container"  ref="logContainer"> </div>
      <div class="video-box">
     
      <div
      v-for="(item) in remoteStreamList"
        :key="item.getUserId()"
        :id="item.getUserId()"
        class=" local-stream-content">
       
      </div>
    </div>
    </div>
    <el-button type="danger" @click="handleLeave">结束会诊并填写报告单</el-button>
  </div>
  <div class="report" v-if="form">
    <div class="border">
      <div class="title">
        <h2>会诊总结报告</h2>
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
      <el-form>
        <div class="form-head">
          <el-form-item label="会诊时间：" style="width: 100%;">
            <el-date-picker type="date" v-model="form3.consultationTime" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="会诊科室：">
            <el-input v-model="form3.consultationClinicName" disabled="" style="width: 100%;" >
          </el-input>
          </el-form-item>
        </div>
        <el-form-item label="会诊意见：" style="width: 100%;" >
          <el-input type="textarea"  v-model="form3.consultationSuggestion">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="会诊诊断：">
          <el-input type="textarea" v-model="form3.diagnosis" style="width: 70%;" >
          </el-input>
        </el-form-item> -->
        <el-form-item label="诊疗意见：">
          <el-input type="textarea" v-model="form3.treatmentSuggestion" >
          </el-input>
        </el-form-item>
        <div class="form-bottom">
          <el-form-item label="是否建议转院：">
            <el-radio-group v-model="form3.transferAdvice">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告医生：">
            <el-input  v-model="form3.reportDoctor"> </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
  <div class='basicInfo'>
  <div class="BasicInfo">
    <div class="username" v-if="form2.name">
      <span>{{form2.name}}</span><span>{{form2.identity}}</span>
    </div>
    <div class="userbasic" v-if="form2.name">
      <span class="title">患者性别：</span><span class="value">    <span v-if="form2.gender=='1'">男</span><span v-if="form2.gender=='2'">女   </span></span>
      <span class="title">患者年龄：</span><span class="value">{{age}}</span>
      <span class="title">婚姻状态：</span><span class="value">{{form3.marriage}}</span>
      <span class="title">职业：</span><span class="value" style="width:100px">{{form3.occupation}}</span>
    </div>
    <!-- <div class="history">
      <el-input type="textarea" disabled :autosize="{ minRows: 3, maxRows: 6 }">
      </el-input>
    </div> -->
    <div class="h2">目前诊断</div>
    <div class="history">
      <el-input type="textarea" v-model="form3.diagnosticResult" disabled :autosize="{ minRows: 3, maxRows: 6 }">
      </el-input>
    </div>
    <div class="h2">申请目的</div>
    <div class="history">
      <el-input type="textarea" v-model="form3.applyPurpose" disabled :autosize="{ minRows: 3, maxRows: 6 }">
      </el-input>
    </div>
    <div class="h2">附件</div>
    <div class="file">
      <el-image
        style="width: 100px; height: 100px"
        :src="url"
        :preview-src-list="srcList"
      >
      </el-image>
      <el-image
        style="width: 100px; height: 100px"
        :src="url"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
  </div>
</div>
    <!-- 显示邀请链接 -->
    <!-- <div v-if="showInviteLink" class="invite-link-container">
      <span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one person,
        the link will be updated automatically after copying.</span>
      <span v-else>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
            :visibleArrow="false"
            effect="dark"
            content="Copied!"
            placement="bottom"
            :manual="true"
            v-model="showCopiedTip">
            <span class="invite-btn" @click="handleCopyInviteLink">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </div> -->



    <!-- 远端流区域 -->
  
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import { getUrlParam } from '@/utils/utils.js';

import axios from 'axios'

export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  props: {
    type: String,

    form1:Object,
    cameraId: String,
    microphoneId: String,

  },
  data() {
    return {
      form3:{},
      inviteUserSig:"",
      form:false,
      userId:'12313132',
      roomId:21313,
      sdkAppId:1400236771,
      secretKey:"83ab78d1a8513af6626d58cc2bacd7b28bfb2af06515fa02b0204129ebb53422",
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      form2:{},
      age:'',
      token:'',
  
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  mounted(){
//     // 创建xhr对象
// let xhr = new XMLHttpRequest()
// // 初始化xhr对象，设置请求方式和请求的url
// xhr.open("GET","http://192.168.124.10:8080//business/patient/getById/"+getUrlParam('patientId'))
// xhr.setRequestHeader('Authorization',getUrlParam('token'))
// // 发送请求
// xhr.send()

// // 处理数据
// xhr.onreadystatechange() = function(){
// 	// 判断服务器是否已经响应完毕
// 	if(xhr.readystate === 4){
// 		// 判断请求是否成功
// 		if(xhr.status === 200){
// 			console.log(xhr.status)  // 响应状态码
// 			console.log(xhr.statusText) // 响应状态文本
// 			console.log(xhr.getAllResponseHeaders()) //获取响应头
// 			console.log(xhr.response) // 获取响应体
// 		}
// 	}
// }
this.token=getUrlParam('token')
axios.get('https://fdmp.xinelu.cn/th/business/getById/'+getUrlParam('patientId'), {headers:{
  Authorization:'Bearer '+this.token
  }}).then(res=>{
 localStorage.setItem('user',JSON.stringify(res))
 this.form2=JSON.parse(window.localStorage.getItem('user')).data.data
 this.age= this.IdCard(this.form2.identity,3)

})
axios.get('https://fdmp.xinelu.cn/th/business/consultation/'+getUrlParam('conId'), {headers:{
  Authorization:'Bearer '+this.token
  }}).then(res=>{
 localStorage.setItem('user1',JSON.stringify(res))
 this.form3=JSON.parse(window.localStorage.getItem('user1')).data.data

})

    this.userId = getUrlParam('userId');
    this.roomId = Number(getUrlParam('roomId'));
    this.generateInviteLink()
    this.handleJoinRoom()
 
  },
  methods: {
    GetQueryString(name)
{
var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
var r = window.location.search.substr(1).match(reg);
if(r!=null)return unescape(r[2]); return null;
},
IdCard(IdCard, type) {
        if (type === 1) {
            //获取出生日期
            let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
            return birthday
        }
        if (type === 2) {
            //获取性别
            if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
                return "男"
            } else {
                return "女"
            }
        }
        if (type === 3) {
            //获取年龄
            var ageDate = new Date()
            var month = ageDate.getMonth() + 1
            var day = ageDate.getDate()
            var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
            if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
                age++
            }
            if (age <= 0) {
                age = 1
            }
            return age
        }},
    submit(){
      this.form3.status=4
      axios.post('https://fdmp.xinelu.cn/th/business/consultation/report',{headers:{
  Authorization:'Bearer '+this.token
  }},this.form3).then(res=>{
console.log(res)
  alert('保存成功');

})
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteUserSig=userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      console.log(this.isHostMode,this.secretKey)
      if (this.isHostMode) {
    
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) { 
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
  if(this.form3.inviteDeptId==getUrlParam('org'))
      this.form=true
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3:1400236771,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: 1400236771,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  padding: 50px 80px;
  width: 70%;

  .border {
    width: 100%;
    border: 2px dashed #ccc;
  }
  .title {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-child(2) {
      position: absolute;
      right: 30px;
    }
  }
 
  .form-head {
    border-top: 1px dashed #ccc;
    display: flex;
     .el-form-item__label {
      color: #333;
      font-size: 16px;
      height: 50px;
    }
    .el-form-item {
      height: 100%;
    }
  }
  .form-bottom {
    display: flex;
    justify-content: space-between;
   .el-form-item__label {
      color: #333;
      font-size: 16px;
      height: 50px;
    }
    .el-form-item {
      height: 100%;
    }
  }
  .el-form-item {
    border-bottom: 1px solid #cecece;
    margin-bottom: 0;
    height: 22vh;
    display: flex;
    align-items: center;
  }
 .el-form-item__label {
    width: 150px;
    font-size: 18px;
    color: #007eff;
    display: flex;
    align-items: center;
    background: #f5f7f8;
    height: 100%;
    margin-right: 10px;
  }
}
.basicInfo{
  width: 25%;
  margin-top: 40px;
    background: #9bc2f1;
    padding: 15px;
}
.BasicInfo {
  background: white;
  padding: 20px;

  width: 100%;
  min-height: 92vh;
  .username {
    display: flex;
    align-items: center;
    :nth-child(1) {
      font-size: 26px;
      color: #007eff;
    }
    :nth-child(2) {
      font-size: 14px;
      color: #333;
      margin-left: 20px;
    }
  }
  .userbasic {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    line-height: 35px;
    width: 300px;
    .title {
      color: #007eff;
      font-size: 16px;
    }
    .value {
      margin-right: 30px;
    }
  }
  .history {
    margin: 15px 0;
  }
  .h2 {
    color: #007eff;
    font-size: 18px;
  }
  .file {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    .el-image {
      margin-right: 15px;
    }
  }
}
.Call {
  width: 70%;
  padding: 50px 80px;
  position: relative;
  .video-box {
    width: 100%;
    height: 80vh;
  }
  .el-button--danger {
    position: absolute;
    top: 50px;
    right: 80px;
  }
}
.rtc-container {
  display: flex;
  width: 100%;
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 100%;
      height: 100%;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        position: sticky;
        bottom:10px;
        width: 200px;
        height: 100px;

      }
      .local-stream-control {
        width: 90%;
        height: 30px;
        position: absolute;
        bottom: 1;
      
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }

  .remote-container {
   
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    .remote-stream-container {
      width: 120px;
      position: fixed;
				bottom: 0;
        z-index: 2;
      right: 37%;
      height: 140px;
      margin: 0 10px 10px 0;
    }
  }
}
</style>
<style>
 .el-form-item__content{
    width: 65%;
  }
  .el-form-item__label{
    width: 140px;
  }
  </style>
<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
  
}
</i18n>
